"use strict";

window.site = window.site || {};

/**
 * Manage animations witrh animjs library
 * @class Animations
 * @static
 */
site.Animations = function Animations() {
  /**
   * Has the class been initialized?
   * @private
   */
  var inited = false;

  /**
   * Initializes the class.
   * @public
   */
  var init = function init() {
    // Abort if already initialized
    if (inited) {
      return false;
    }

    inited = true;

    initAnimation();

    return true;
  };

  var doTricksWord = function doTricksWord() {
    var tricksWord = document.getElementsByClassName("tricks");
    for (var i = 0; i < tricksWord.length; i++) {

      var wordWrap = tricksWord.item(i);
      wordWrap.innerHTML = wordWrap.innerHTML.replace(/(^|<\/?[^>]+>|\s+)([^\s<]+)/g, '$1<span class="tricksword">$2</span>');
    }
  };

  var doTricksLetter = function doTricksLetter() {
    var tricksLetter = document.getElementsByClassName("tricksword");
    for (var i = 0; i < tricksLetter.length; i++) {

      var letterWrap = tricksLetter.item(i);
      letterWrap.innerHTML = letterWrap.textContent.replace(/\S/g, "<span class='letter'>$&</span>");
    }
  };

  var initAnimation = function initAnimation() {
    doTricksWord();
    doTricksLetter();

    var popIn = anime.timeline({
      loop: false,
      autoplay: false
    });

    popIn.add({
      targets: '.pop-in .letter',
      translateY: [100, 0],
      translateZ: 0,
      opacity: [0, 1],
      easing: "easeOutExpo",
      duration: 1200,
      delay: function delay(el, i) {
        return 30 * i;
      }
    });

    inView('[data-animated-title]').on('enter', function () {
      popIn.play();
    });
  };

  // Expose public methods & properties
  return {
    init: init
  };
}();