'use strict';

window.site = window.site || {};

/**
 * Utilities for Google Analytics.
 * @class Hero
 * @static
 */
site.Hero = function Hero() {
  /**
   * Has the class been initialized?
   * @private
   */
  var inited = false;

  /**
   * Initializes the class.
   * @public
   */
  var init = function init() {
    // Abort if already initialized
    if (inited) {
      return false;
    }

    inited = true;

    window.onload = deferIframe;

    return true;
  };

  var deferIframe = function deferIframe() {
    var heroVideoContainer = document.querySelector('.home-hero');

    if (heroVideoContainer) {
      heroVideoContainer.style.height = window.innerHeight + 'px';

      var heroVideoElement = heroVideoContainer.querySelector('.home-hero-video');
      if (heroVideoElement) {
        if (window.innerWidth <= 600) {
          heroVideoElement.src = heroVideoElement.dataset.srcSmall;
        } else {
          heroVideoElement.src = heroVideoElement.dataset.srcLarge;
        }

        window.addEventListener('resize', function () {
          if (window.innerWidth <= 600 && heroVideoElement.src !== heroVideoElement.dataset.srcSmall) {
            heroVideoElement.src = heroVideoElement.dataset.srcSmall;
          } else if (window.innerWidth > 600 && heroVideoElement.src !== heroVideoElement.dataset.srcLarge) {
            heroVideoElement.src = heroVideoElement.dataset.srcLarge;
          }
        });
      }
    }
  };

  // Expose public methods & properties
  return {
    init: init
  };
}();