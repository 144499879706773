"use strict";

window.site = window.site || {};

/**
 * Submit forms
 * @class Forms
 * @static
 */
site.Forms = function Forms() {
  /**
   * Has the class been initialized?
   * @private
   */
  var inited = false;

  /**
   * Initializes the class.
   * @public
   */
  var init = function init() {
    // Abort if already initialized
    if (inited) {
      return false;
    }

    inited = true;

    var contactForms = [].slice.call(document.querySelectorAll('.contact-form'));

    if (contactForms.length) {
      contactForms.forEach(function (form) {
        setTimeout(function () {
          var $routing = document.createElement("input");

          $routing.setAttribute("type", "hidden");
          $routing.setAttribute("name", "action");
          $routing.setAttribute("value", "contact-form/send");

          form.appendChild($routing);
        }, 4000);

        var pristine = new Pristine(form);
        var submitBtn = form.querySelector('button[type="button"]');

        if (submitBtn) {
          submitBtn.addEventListener('click', function (e) {
            // Check for message input to be filled
            var $messageInput = form.querySelector('textarea[name="message[body]"]');
            $messageInput.value = $messageInput.value.length ? $messageInput.value : 'No message';

            if (pristine.validate()) {
              window.history.pushState({}, null, '#contact');

              form.submit();
            }
          });
        }
      });
    }

    var applicationForms = [].slice.call(document.querySelectorAll('.application-form'));

    if (applicationForms.length) {
      applicationForms.forEach(function (form) {
        setTimeout(function () {
          var $routing = document.createElement("input");

          $routing.setAttribute("type", "hidden");
          $routing.setAttribute("name", "action");
          $routing.setAttribute("value", "contact-form/send");

          form.appendChild($routing);
        }, 4000);

        var pristine = new Pristine(form);
        var submitBtn = form.querySelector('button[type="button"]');
        var input = form.querySelector('input[name="attachment[]"]');

        if (submitBtn) {
          submitBtn.addEventListener('click', function (e) {
            // Check for message input to be filled
            var $messageInput = form.querySelector('textarea[name="message[body]"]');
            $messageInput.value = $messageInput.value.length ? $messageInput.value : 'No message';

            var container = form.querySelector('.form-files-list');

            if (container && input) {
              var oldFileErrorElement = container.nextElementSibling;

              if (oldFileErrorElement) {
                if (oldFileErrorElement.classList.contains('pristine-error') && oldFileErrorElement.classList.contains('for-attachments')) {
                  oldFileErrorElement.remove();
                }
              }

              var fileErrorElement = document.createElement('div');
              fileErrorElement.classList.add('pristine-error');
              fileErrorElement.classList.add('text-help');
              fileErrorElement.classList.add('for-attachments');

              if (pristine.validate() && (input.files.length >= 1 || input.files.length <= 2)) {
                form.submit();
              } else if (input.files.length === 0) {
                container.after(fileErrorElement);
                fileErrorElement.innerHTML = "<svg width=\"15\" height=\"15\" viewBox=\"0 0 15 15\" fill=\"none\" xmlns=\"http://www.w3.org/2000/svg\">\n                <path d=\"M7.5 15C3.35 15 0 11.65 0 7.5C0 3.35 3.35 0 7.5 0C11.65 0 15 3.35 15 7.5C15 11.65 11.65 15 7.5 15ZM7.5 1C3.9 1 1 3.9 1 7.5C1 11.1 3.9 14 7.5 14C11.1 14 14 11.1 14 7.5C14 3.9 11.1 1 7.5 1Z\" fill=\"#880000\"/>\n                <path d=\"M7.5 3C8.05 3 8.5 3.45 8.5 4V7.85714C8.5 8.40714 8.05 8.85714 7.5 8.85714C6.95 8.85714 6.5 8.40714 6.5 7.85714V4C6.5 3.45 6.95 3 7.5 3Z\" fill=\"#880000\"/>\n                <path d=\"M7.5 10C8.05 10 8.5 10.45 8.5 11C8.5 11.55 8.05 12 7.5 12C6.95 12 6.5 11.55 6.5 11C6.5 10.45 6.95 10 7.5 10Z\" fill=\"#880000\"/>\n                </svg>" + input.dataset.pristineRequiredMessage;
              }
            }
          });
        }
      });
    }

    return true;
  };

  // Expose public methods & properties
  return {
    init: init
  };
}();