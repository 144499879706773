'use strict';

window.site = window.site || {};

/**
 * Utilities to handle scrollTo
 * @class ScrollTo
 * @static
 */
site.ScrollTo = function ScrollTo() {
  /**
   * Has the class been initialized?
   * @private
   */
  var inited = false;

  /**
   * Initializes the class.
   * @public
   */
  var init = function init() {
    // Abort if already initialized
    if (inited) {
      return false;
    }

    inited = true;

    var $scrollLinks = [].slice.call(document.querySelectorAll('[data-scroll-to]'));

    $scrollLinks.forEach(function (item) {
      item.addEventListener('click', function (e) {
        e.preventDefault();

        if (item.classList.contains('header-menu-link')) {
          var $activeItem = document.querySelector('.header-menu-link.is-active');

          if ($activeItem && item !== $activeItem) $activeItem.classList.remove('is-active');

          item.classList.add('is-active');
        }

        var $mobileMenu = document.querySelector('.header .header-menu');
        var $triggerMobileMenu = document.querySelector('#triggerMobileMenu');

        if (window.innerWidth <= 768 && $mobileMenu && $triggerMobileMenu) {
          $mobileMenu.classList.remove('is-active');
          document.body.style.overflowY = 'auto';
          $triggerMobileMenu.checked = false;
        }

        var selector = item.getAttribute('data-scroll-to');

        var $zone = document.querySelector(selector);

        var topPos = $zone.getBoundingClientRect().top + window.scrollY;

        window.scroll({
          top: topPos,
          behavior: 'smooth'
        });
      });
    });

    return true;
  };

  // Expose public methods & properties
  return {
    init: init
  };
}();