'use strict';

window.site = window.site || {};

/**
 * Utilities for Google Analytics.
 * @class Track
 * @static
 */
site.CustomSelect = function CustomSelect() {
  /**
   * Has the class been initialized?
   * @private
   */
  var inited = false;

  /**
   * Initializes the class.
   * @public
   */
  var init = function init() {
    // Abort if already initialized
    if (inited) {
      return false;
    }

    inited = true;

    var selects = document.querySelectorAll('select.form-custom-select');

    if (selects.length) {
      selects.forEach(function (select) {
        var numberOfOptions = select.children.length;

        select.classList.add('custom-select-hidden');
        var wrapper = document.createElement('div');
        wrapper.classList.add('custom-select-wrapper');
        wrap(select, wrapper);

        var $styledSelect = document.createElement('div');
        $styledSelect.classList.add('custom-select');
        select.after($styledSelect);

        $styledSelect.textContent = select.children[0].textContent;

        var $list = document.createElement('ul');
        $list.classList.add('custom-select-options');
        $styledSelect.after($list);

        var _loop = function _loop(i) {
          var $listElement = document.createElement('li');
          $listElement.textContent = select.children[i].textContent;
          $listElement.rel = select.children[i].textContent;

          $listElement.addEventListener('click', function (e) {
            e.stopPropagation();

            $styledSelect.textContent = $listElement.textContent;
            $styledSelect.classList.remove('is-active');
            select.value = select.children[i].value;
            $list.style.display = 'none';
          });

          $list.append($listElement);
        };

        for (var i = 0; i < numberOfOptions; i++) {
          _loop(i);
        }

        $styledSelect.addEventListener('click', function (e) {
          e.stopPropagation();

          document.querySelectorAll('div.custom-select.is-active').filter(function (elem) {
            return elem !== $styledSelect;
          }).forEach(function (styledSelect) {
            styledSelect.classList.remove('is-active');
            styledSelect.nextElementSibling.style.display = 'none';
          });

          $styledSelect.classList.toggle('is-active');

          if ($styledSelect.classList.contains('is-active')) {
            $styledSelect.nextElementSibling.style.display = 'block';
          } else {
            $styledSelect.nextElementSibling.style.display = 'none';
          }
        });
      });
    }

    return true;
  };

  var wrap = function wrap(el, wrapper) {
    el.before(wrapper);
    wrapper.append(el);
  };

  // Expose public methods & properties
  return {
    init: init
  };
}();