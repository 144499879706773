'use strict';

window.site = window.site || {};

/**
 * Utilities for Google Analytics.
 * @class Track
 * @static
 */
site.Careers = function Careers() {
  /**
   * Has the class been initialized?
   * @private
   */
  var inited = false;

  /**
   * Initializes the class.
   * @public
   */
  var init = function init() {
    // Abort if already initialized
    if (inited) {
      return false;
    }

    inited = true;

    var accordions = [].slice.call(document.querySelectorAll('.home-careers-items .careers-accordion'));
    var applicationFormPopupConfirmation = document.querySelector('.popup-application-form.confirmation');

    if (accordions.length) {
      accordions.forEach(function (element) {
        var header = element.querySelector('.accordion-header');
        var applyNowButton = element.querySelector('button.apply-now');

        if (header && applyNowButton) {
          applyNowButton.addEventListener('click', function () {
            var jobTitle = header.textContent;
            var applicationFormPopup = document.querySelector('.popup-application-form');

            if (applicationFormPopup) {
              applicationFormPopup.classList.add('is-active');
              document.body.style.overflowY = 'hidden';

              var select = applicationFormPopup.querySelector('form select.form-custom-select');

              if (select) {
                console.log(jobTitle);
                select.value = 'Application - ' + jobTitle.trim();

                var $styledSelect = applicationFormPopup.querySelector('form .custom-select');

                if (styledSelect) {
                  $styledSelect.textContent = jobTitle;
                }
              }

              var closeButton = applicationFormPopup.querySelector('button.application-form-close');

              if (closeButton) {
                closeButton.addEventListener('click', function () {
                  var form = applicationFormPopup.querySelector('form');

                  if (form) {
                    form.reset();
                    var inputFile = form.querySelector('input[name="attachment[]"]');

                    if (inputFile) {
                      inputFile.files = null;
                      inputFile.dispatchEvent(new Event('change'));
                    }
                  }

                  applicationFormPopup.classList.remove('is-active');
                  document.body.style.overflowY = 'auto';
                });
              }
            }
          });
        }
      });
    }

    if (applicationFormPopupConfirmation) {
      if (applicationFormPopupConfirmation.classList.contains('is-active')) {
        document.body.style.overflowY = 'hidden';
      }
      var closeButton = applicationFormPopupConfirmation.querySelector('button.application-form-close');

      if (closeButton) {
        closeButton.addEventListener('click', function () {
          window.history.pushState({}, null, '/');
          applicationFormPopupConfirmation.classList.remove('is-active');
          document.body.style.overflowY = 'auto';
        });
      }
    }

    return true;
  };

  // Expose public methods & properties
  return {
    init: init
  };
}();