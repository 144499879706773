'use strict';

window.site = window.site || {};

/**
 * Manage accordions
 * @class Accordions
 * @static
 */
site.HomeAccordions = function HomeAccordions() {
  /**
   * Has the class been initialized?
   * @private
   */
  var inited = false;

  /**
   * Initializes the class.
   * @public
   */
  var init = function init() {
    // Abort if already initialized
    if (inited) {
      return false;
    }

    inited = true;

    var accordions = [].slice.call(document.querySelectorAll('.home-careers-items .careers-accordion'));

    if (accordions.length) {
      accordions.forEach(function (element) {
        var header = element.querySelector('.accordion-header');

        if (header) {
          header.addEventListener('click', function () {
            // Close the active accordion
            var currentAccordion = document.querySelector('.home-careers-items .careers-accordion.is-active');
            if (currentAccordion && currentAccordion !== element) {
              currentAccordion.classList.toggle('is-active');
            }

            element.classList.toggle('is-active');
          });
        }
      });
    }

    return true;
  };

  // Expose public methods & properties
  return {
    init: init
  };
}();