'use strict';

window.site = window.site || {};

/**
 * Utilities for Google Analytics.
 * @class Track
 * @static
 */
site.Files = function Files() {
  /**
   * Has the class been initialized?
   * @private
   */
  var inited = false;

  /**
   * Initializes the class.
   * @public
   */
  var init = function init() {
    // Abort if already initialized
    if (inited) {
      return false;
    }

    inited = true;

    // applying the effect for every form
    var forms = document.querySelectorAll('[data-form-with-attachment]');

    if (forms.length) {
      forms.forEach(function (form) {
        var input = form.querySelector('input[type="file"]');
        var container = form.querySelector('.form-files-list');
        var droppedFiles = false;

        // automatically submit the form on file select
        input.addEventListener('change', function (e) {
          showFiles(e.target.files, container, input);
        });

        // drag&drop files if the feature is available
        if (isAdvancedUpload()) {
          // letting the CSS part to know drag&drop is supported by the browser
          form.classList.add('has-advanced-upload');

          ['drag', 'dragstart', 'dragend', 'dragover', 'dragenter', 'dragleave', 'drop'].forEach(function (event) {
            form.addEventListener(event, function (e) {
              // preventing the unwanted behaviours
              e.preventDefault();
              e.stopPropagation();
            });
          });

          ['dragover', 'dragenter'].forEach(function (event) {
            form.addEventListener(event, function () {
              form.classList.add('is-dragover');
            });
          });

          ['dragleave', 'dragend', 'drop'].forEach(function (event) {
            form.addEventListener(event, function () {
              form.classList.remove('is-dragover');
            });
          });

          form.addEventListener('drop', function (e) {
            // the files that were dropped
            droppedFiles = e.dataTransfer.files;
            showFiles(droppedFiles, container, input);
          });
        }

        // Firefox focus bug fix for file input
        input.addEventListener('focus', function () {
          return input.classList.add('has-focus');
        });
        input.addEventListener('blur', function () {
          return input.classList.remove('has-focus');
        });
      });
    }

    return true;
  };

  // Feature detection for drag&drop upload
  var isAdvancedUpload = function isAdvancedUpload() {
    var div = document.createElement('div');
    return ('draggable' in div || 'ondragstart' in div && 'ondrop' in div) && 'FormData' in window && 'FileReader' in window;
  };

  var showFiles = function showFiles(files, container, input) {
    container.classList.add('is-empty');
    container.innerHTML = '';

    var allowedTypes = ['png', 'jpg', 'jpeg', 'pdf', 'doc', 'docx'];
    var minFiles = input.dataset.minFiles;
    var maxFiles = input.dataset.maxFiles;

    var oldFileErrorElement = container.nextElementSibling;

    if (oldFileErrorElement) {
      if (oldFileErrorElement.classList.contains('pristine-error') && oldFileErrorElement.classList.contains('for-attachments')) {
        oldFileErrorElement.remove();
      }
    }

    var fileErrorElement = document.createElement('div');
    fileErrorElement.classList.add('pristine-error');
    fileErrorElement.classList.add('text-help');
    fileErrorElement.classList.add('for-attachments');

    if (files.length >= minFiles && files.length <= maxFiles) {
      input.files = files;

      Array.from(files).forEach(function (file, index) {
        var isAllowed = true;

        if (!allowedTypes.includes(file.name.split('.').pop())) {
          isAllowed = false;
        }

        if (isAllowed) {
          container.classList.remove('is-empty');

          var fileElement = document.createElement('p');
          fileElement.classList.add('regular-text');
          fileElement.classList.add('regular-text');
          fileElement.innerHTML = '\n            <svg width="7" height="15" viewBox="0 0 7 15" fill="none" xmlns="http://www.w3.org/2000/svg">\n              <path d="M3.18756 15C4.94695 15 6.37504 13.5719 6.37504 11.8125L6.37504 2.24997C6.37504 1.00996 5.36508 -1.18819e-06 4.12506 -1.13399e-06C2.88505 -1.07978e-06 1.87509 1.00996 1.87509 2.24998L1.87509 11.8125C1.87509 12.5332 2.46692 13.125 3.18756 13.125C3.9082 13.125 4.50003 12.5332 4.50003 11.8125L4.50003 3.56248L4.50016 3.56235C4.50134 3.46216 4.46236 3.36551 4.39187 3.29423C4.3215 3.22295 4.22537 3.1828 4.12505 3.1828C4.02487 3.1828 3.92874 3.22295 3.85824 3.29423C3.78787 3.36564 3.7489 3.46216 3.75007 3.56235L3.75008 11.8124C3.75008 12.1305 3.50576 12.3749 3.18754 12.3749C2.86946 12.3749 2.625 12.1305 2.625 11.8124L2.625 2.24984C2.625 1.41238 3.28745 0.749805 4.12504 0.749805C4.9625 0.749805 5.62508 1.41238 5.62508 2.24984L5.62508 11.8124C5.62508 13.1692 4.54438 14.2499 3.18754 14.2499C1.8307 14.2499 0.749995 13.1694 0.749995 11.8124L0.749994 3.18735L0.750125 3.18735C0.751564 3.08703 0.712588 2.99025 0.642222 2.91871C0.571725 2.84717 0.475463 2.80701 0.375147 2.80701C0.2747 2.80701 0.178438 2.84717 0.107941 2.91871C0.0375749 2.99025 -0.00139857 3.08703 3.78274e-05 3.18735L3.82044e-05 11.8124C3.82813e-05 13.5718 1.42813 14.9999 3.18752 14.9999L3.18756 15Z" fill="white"/>\n            </svg>\n            <span>\n              ' + file.name + '\n            </span>\n            <button typ="button" data-remove-file>\n              <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">\n                <path fill-rule="evenodd" clip-rule="evenodd" d="M5.78215 4.99999L9.99997 0.782164L9.21781 0L4.99998 4.21782L0.782187 2.80683e-05L2.32937e-05 0.782192L4.21782 4.99999L0 9.21781L0.782164 9.99997L4.99998 5.78215L9.21783 10L9.99999 9.21783L5.78215 4.99999Z" fill="#8DCDF2"/>\n              </svg>          \n            </button>\n          ';
          container.append(fileElement);

          var removeBtn = fileElement.querySelector('[data-remove-file]');

          if (removeBtn) {
            removeBtn.addEventListener('click', function (e) {
              e.preventDefault();
              var dt = removeFileFromFileList(index, files);

              input.files = dt.files;
              input.dispatchEvent(new Event('change'));
            });
          }
        } else {
          var dt = removeFileFromFileList(index, files);

          input.files = dt.files;
          input.dispatchEvent(new Event('change'));
        }
      });
    } else if (files.length === 0) {
      fileErrorElement.innerHtml = '<svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">\n      <path d="M7.5 15C3.35 15 0 11.65 0 7.5C0 3.35 3.35 0 7.5 0C11.65 0 15 3.35 15 7.5C15 11.65 11.65 15 7.5 15ZM7.5 1C3.9 1 1 3.9 1 7.5C1 11.1 3.9 14 7.5 14C11.1 14 14 11.1 14 7.5C14 3.9 11.1 1 7.5 1Z" fill="#880000"/>\n      <path d="M7.5 3C8.05 3 8.5 3.45 8.5 4V7.85714C8.5 8.40714 8.05 8.85714 7.5 8.85714C6.95 8.85714 6.5 8.40714 6.5 7.85714V4C6.5 3.45 6.95 3 7.5 3Z" fill="#880000"/>\n      <path d="M7.5 10C8.05 10 8.5 10.45 8.5 11C8.5 11.55 8.05 12 7.5 12C6.95 12 6.5 11.55 6.5 11C6.5 10.45 6.95 10 7.5 10Z" fill="#880000"/>\n      </svg>' + input.dataset.pristineRequiredMessage;
      container.after(fileErrorElement);
    } else {
      fileErrorElement.innerHtml = '<svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">\n      <path d="M7.5 15C3.35 15 0 11.65 0 7.5C0 3.35 3.35 0 7.5 0C11.65 0 15 3.35 15 7.5C15 11.65 11.65 15 7.5 15ZM7.5 1C3.9 1 1 3.9 1 7.5C1 11.1 3.9 14 7.5 14C11.1 14 14 11.1 14 7.5C14 3.9 11.1 1 7.5 1Z" fill="#880000"/>\n      <path d="M7.5 3C8.05 3 8.5 3.45 8.5 4V7.85714C8.5 8.40714 8.05 8.85714 7.5 8.85714C6.95 8.85714 6.5 8.40714 6.5 7.85714V4C6.5 3.45 6.95 3 7.5 3Z" fill="#880000"/>\n      <path d="M7.5 10C8.05 10 8.5 10.45 8.5 11C8.5 11.55 8.05 12 7.5 12C6.95 12 6.5 11.55 6.5 11C6.5 10.45 6.95 10 7.5 10Z" fill="#880000"/>\n      </svg>' + input.dataset.pristineMinMaxMessage;
      container.after(fileErrorElement);
    }
  };

  var removeFileFromFileList = function removeFileFromFileList(index, files) {
    var dt = new DataTransfer();
    console.log(files);

    for (var i = 0; i < files.length; i++) {
      var file = files[i];
      if (index !== i) dt.items.add(file);
    }

    console.log(dt.files);

    return dt;
  };

  // Expose public methods & properties
  return {
    init: init
  };
}();