'use strict';

window.site = window.site || {};

/**
 * Header of the website
 * @static
 */
site.Header = function Header() {
  /**
   * Has the class been initialized?
   * @private
   */
  var inited = false;

  /**
   * Initializes the class.
   * @public
   */
  var init = function init() {
    // Abort if already initialized
    if (inited) {
      return false;
    }

    inited = true;

    var $triggerMobileMenu = document.querySelector('#triggerMobileMenu');

    if ($triggerMobileMenu) {
      var $mobileMenu = document.querySelector('.header .header-menu');

      $triggerMobileMenu.addEventListener('change', function () {
        if ($mobileMenu) {
          $mobileMenu.classList.toggle('is-active');

          if ($mobileMenu.classList.contains('is-active')) document.body.style.overflowY = 'hidden';else document.body.style.overflowY = 'auto';
        }
      });
    }

    return true;
  };

  // Expose public methods & properties
  return {
    init: init
  };
}();